import React from "react";
import LoaderImg from "../images/loader.svg"

const Loader = () => {
    return (
        <div className="loader">
            <img src={LoaderImg} alt="loading" />
        </div>
    )
}

export default Loader
import * as React from "react"
import { Suspense, lazy } from "@uploadcare/client-suspense"
import Breadcrumbs from "@components/Breadcrumbs"
import Layout from "@components/layout"
import Seo from "@components/Seo"
import PostCategorys from "@components/posts/PostCategorys"
import PostNavigation from "@components/PostNavigation"
import Calculator from "@components/Calculator"
// import MiniTrial from "../components/banners/MiniTrial"
import Loader from "@components/Loader"
import Sharing from "@components/Sharing";
const RelatedPosts = lazy(() => import('@components/RelatedPosts'));

const Landing = ({pageContext}) => {
    const post = pageContext
    // const [hideVideo, setHideVideo] = useState(true)
    const steps = [
        {
            name: 'Writing services',
            path: '/writing-services'
        },
        {
            name: post.post_slug.replace(/-/g, ' '),
            path: ''
        }
    ]

    // seo data
    const pageData = {
        title: post.post_meta_title,
        description: `⭐ ${post.post_meta_description}`,
        keywords: post.post_keyword_singular + `,` + post.post_keyword_plural,
        robots: 'index, follow',
        micromarking: `
            {
                "@context": "https://schema.org",
                "@type": "Product",
                "name": "${post.post_keyword_singular}",
                "image": [
                    "https://www.genuinewriting.com/images/logo.png"
                ],
                "brand": {
                    "@type": "Brand",
                    "name": {
                        "@type": "Thing",
                        "name": "GenuineWriting.com"
                    }
                },
                "description": "${post.post_meta_description}",
                "review": [
                    {
                        "@type": "Review",
                        "name": "Review",
                        "author": {
                            "@type": "Person",
                            "name": "Anonymous Client"
                        },
                        "reviewRating": {
                            "@type": "Rating",
                            "name": "Rating",
                            "worstRating": "1",
                            "bestRating": "10",
                            "ratingValue": "9"
                        }
                    }
                ],
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "name": "AggregateRating",
                    "ratingValue": "9.25",
                    "worstRating": "1",
                    "bestRating": "10",
                    "reviewCount": "65074"
                },
                "offers": {
                    "@type": "AggregateOffer",
                    "name": "AggregateOffer",
                    "lowPrice": "14.95",
                    "highPrice": "34.95",
                    "priceCurrency": "USD",
                    "offerCount": "1"
                }
            }
        `
    }
    
    return (
        <Layout>
            <Seo {...pageData} />
            <div className="landing-intro page-intro">
                <div className="landing-intro__wrapper wrapper">
                    <div className="landing-intro__info">
                        <Breadcrumbs steps={steps}/>
                        <h1 className="page-intro__title landing-intro__title">{post.post_title_primary}</h1>
                        <PostCategorys info={post}/>
                        <ul className="intro-list">
                            <li className="intro-description">We can write your {post.post_keyword_singular} on any topic</li>
                            <li className="intro-description">Deliver your {post.post_keyword_singular} on time</li>
                            <li className="intro-description">100% Satisfaction & moneyback for your {post.post_keyword_singular}</li>
                        </ul>
                        <div className="landing-intro__actions">
                            <a href={'https://shop.genuinewriting.com/order/new'} className="button button-primary">{post.post_button_keyword || `Order now`}</a>
                            {/* <div aria-hidden className="button button-video" onClick={() => setHideVideo(false)}>
                                <span className="button-video__icon"></span>
                            </div> */}
                        </div>
                    </div>
                    {/* <div className="landing-intro__banner">
                        <MiniTrial />
                    </div> */}
                    {/* {!hideVideo && 
                    <div className="video">
                        <div className="video-modal">
                            <p className="video-modal__title">
                                Video introduction
                                <span aria-hidden onClick={() => setHideVideo(true)}>
                                    <img src={Cross} alt="close" />
                                </span>
                            </p>
                            <div className="iframe_container">
                                <iframe
                                    src="https://www.youtube.com/embed/8MfY7csqKgo"
                                    title="YouTube video player"
                                />
                            </div>
                        </div>
                    </div>
                    } */}
                </div>
            </div>
            <Calculator />
            <div className="post__content landing-post__content wrapper">
                <div className="post__content-wrapper">
                    {/* <Suspense fallback={<Loader/>}>
                        <TheVideo image='curveOval'/>
                    </Suspense> */}
                    {post.table_of_contents.length > 0 && <PostNavigation table={post.table_of_contents}/>}
                    <div
                        className="content-block"
                        dangerouslySetInnerHTML={{ __html: post.processed_content }}
                    />
                    <Sharing
                        url={`https://www.genuinewriting.com/${post.post_slug}.html`}
                        title={post.post_meta_title}
                        description={post.post_meta_description}
                        image="https://www.genuinewriting.com/img/og_image.png"
                        tags={["genuinewriting", "writing"]}
                    />
                </div>
                <div className="post__content-banners">
                    <div className="banners-wrapper">
                        <Suspense fallback={<Loader/>}>
                            <RelatedPosts posts={post.related} slug={post.post_slug} path="landing"/>
                            {/* <SidebarTrial /> */}
                        </Suspense>
                    </div>
                </div>
            </div>
            
            {/* <Suspense fallback={<></>}>
                <PopupDiscount />
                <PopupDeadline />
            </Suspense> */}
        </Layout>
    )
}
export default Landing

import React from 'react'

import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
} from 'react-share'

const Sharing = ({title, url, image, tags, description}) => {

    return(
        <div className='social-share-container'>
            <span className="social-share-button"></span>
            <FacebookShareButton url={url} >
                <span className='social-share-items social-share-items_facebook'></span>
            </FacebookShareButton>

            <TwitterShareButton url={url} title={title} hashtags={tags}>
                <span className='social-share-items social-share-items_twitter'></span>
            </TwitterShareButton>

            <PinterestShareButton url={url} media={image}>
                <span className='social-share-items social-share-items_pinterest'></span>
            </PinterestShareButton>

            <RedditShareButton url={url} title={title} description={description} >
                <span className='social-share-items social-share-items_reddit'></span>
            </RedditShareButton>
        </div>
      )

}
export default Sharing

import React, { useEffect, useState, useRef } from "react"

const PostNavigation = (props) => {
    const [scrollVisibility, setScrollVisibility] = useState(false);
    const [listHeight, setlistHeight] = useState();
    const [containerHeight, setContainerHeight] = useState();
    const list = useRef()
    const navContainer = useRef()
    
    useEffect(() => {
        setlistHeight(list.current.offsetHeight)
        setContainerHeight(navContainer.current.offsetHeight)
        let contentPosition = document.querySelector('.post-navigation__container').offsetTop
        const onScroll = () => {
            contentPosition = document.querySelector('.post-navigation__container').offsetTop
            contentPosition < window.pageYOffset ? setScrollVisibility(true) : setScrollVisibility(false)
        }
        // clean up code
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    return (
        <div
            className="post-navigation__container"
            style={{height: containerHeight}}
        >
            <div
                aria-hidden
                className={`post-navigation ${scrollVisibility ? `hide` : ``}`}
                ref={navContainer}
            >
                <div className="post-navigation__wrapper">
                    <div className="post-navigation__header">
                        TABLE OF CONTENTS
                    </div>
                    <ul 
                        className="post-navigation__list"
                        style={{height: listHeight}}
                        ref={list}
                    >
                        {props.table.map((title, index) => {
                            return(
                                <li
                                    aria-hidden
                                    className="post-navigation__title"
                                    key={index}
                                    onClick={() => scrollToTitle(title.slug)}
                                >
                                    {title.title}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
    
    function scrollToTitle(name) {
        window.history.pushState({}, "", `#${name}`)
        let element = null
        const yOffset = -180
        let yAxis = null
        const collection = document.querySelectorAll('.content-block a')
        collection.forEach((e) => {
            if (e.name === name) {
                element = e
                yAxis = element.getBoundingClientRect().top + window.pageYOffset + yOffset
                window.scrollTo({ top: yAxis , behavior: 'smooth' })
            }
        })        
    }
}

export default PostNavigation
